<template>
  <modal
    name="task-reply-modal"
    transition="pop-out"
    class="task-reply-modal"
    :width="600"
    :focus-trap="true"
    :height="620"
  >
    <div class="reply-modal-container">
      <div class="modal-title border-bottom position-relative">
        Task Reply
        <button class="close mr-3 pr-2" @click="closeModal">&times;</button>
      </div>
      <div class="p-3 content-container">
        <form @submit.prevent="saveReply">
          <div class="form-group column align-items-center">
            <div class="col text-center text-main">Original Message</div>
            <div id="input-control-message" class="col card ml-0 mr-0 mb-1 p-0">
              <div
                class="card-body description light-shadow radius-36"
                v-html="task.description"
              ></div>
            </div>
          </div>

          <div
            class="
              form-group
              column
              align-items-center
              text-center
              position-relative
            "
          >
            <label
              for="input-control-description"
              class="
                col-3
                mb-2
                col-form-label
                text-nowrap text-center text-main
              "
            >
              Your Reply
            </label>
            <span class="col m-0 p-0 text-black mention-hint"
              >Use @ for mention</span
            >
            <div class="col p-0 light-shadow radius-36">
              <ckeditor
                :editor="editor"
                v-model="replyDescription"
                :config="editorConfig"
              >
              </ckeditor>
            </div>
          </div>
          <div class="row justify-content-center mt-0">
            <save :saving="saving">Reply</save>
          </div>
        </form>
      </div>
    </div>
  </modal>
</template>

<script>
import Swal from "sweetalert2";
import { mapState } from "vuex";
import { richTextConfig } from "./RichTextConfig";

export default {
  name: "TaskReplyModal",
  props: {
    task: {
      type: Object,
      required: true,
    },
    users: {
      type: Array,
      required: true,
    },
  },
  watch: {
    task: function () {
      this.taskMessage = this.task.message;
    },
  },
  data() {
    return {
      saving: false,
      replyDescription: "",
      taskMessage: this.task.message,
      editor: richTextConfig.classicEditor,
      editorConfig: {
        placeholder: richTextConfig.placeholder,
        plugins: richTextConfig.plugins,
        ...richTextConfig.basicToolbar,
        mention: {
          feeds: [
            {
              ...richTextConfig.feeds,
              feed: this.getFeedItems,
            },
          ],
        },
      },
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      customToolbar: (state) => state.settings.customToolbar,
      patientProviders: (state) => state.patients.providers,
    }),
  },
  methods: {
    saveReply: async function () {
      try {
        this.saving = true;
        let data;
        const dataToSend = {
          task_id: this.task.id,
          description: this.replyDescription,
        };
        const replyTaggedUsers =
          this.replyDescription.split('data-mention-id="');
        const replyTaggedUsersIds = [];
        replyTaggedUsers.forEach((user, index) => {
          if (index > 0 && user.split('"')[0]) {
            replyTaggedUsersIds.push(user.split('"')[0]);
          }
        });
        dataToSend.tagged_users = replyTaggedUsersIds;

        dataToSend.replyText = window.$(this.replyDescription).text();
        if (dataToSend.replyText.length > 500) {
          dataToSend.replyText = `${dataToSend.replyText.substring(0, 497)}...`;
        }

        data = await this.$http.put(`tasks/${this.task.id}/reply`, dataToSend);
        this.saving = false;
        if (data) {
          Swal.fire({
            title: "",
            text: "Reply Sent Successfully",
            icon: "success",
          });
          this.closeModal();
          this.$emit("replied");
        }
      } catch (err) {
        this.saving = false;
        Swal.fire({
          title: "Reply Failed",
          text: err.data ? err.data.message : err,
          icon: "error",
        });
      }
    },
    closeModal: function () {
      this.replyDescription = "";
      this.$modal.hide("task-reply-modal");
    },
    getFeedItems: function (queryText) {
      let usersToDisplay = this.users;
      if (this.user && this.user.isPatient) {
        const providers = this.patientProviders.data.map((provider) =>
          parseInt(provider.user_id)
        );
        usersToDisplay = this.users.filter((user) =>
          providers.includes(user.id)
        );
      }
      return richTextConfig.getFeedItems(
        queryText,
        Object.assign(usersToDisplay)
      );
    },
  },
};
</script>
<style lang="scss">
.reply-modal-container {
  height: 100%;

  .content-container {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100% - 60px);
    background-color: var(--body-bg-color);
  }

  .ck-rounded-corners .ck.ck-editor__main > .ck-editor__editable,
  .ck.ck-editor__main > .ck-editor__editable.ck-rounded-corners {
    max-height: 235px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  #input-control-message {
    .form-group {
      align-items: center;
    }
  }

  #input-control-message {
    .description {
      max-height: 170px;
      overflow-y: auto;
      margin-bottom: 0;
      opacity: 1;
      color: #222222;
    }
  }
  .modal-title {
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    font-size: 26px;
    background-color: #222222;
    color: #ffffff;
  }
  .close {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    font-size: 1.5em;
    opacity: 0.9;
    color: #ffffff;
    transition: all 0.2s ease-in-out;
  }
}

.pop-out-enter-active,
.pop-out-leave-active {
  transition: all 0.5s;
}
.pop-out-enter,
.pop-out-leave-active {
  opacity: 0;
  transform: translateY(24px);
}

.notify-hint {
  transition: all 0.5s;
  margin-top: -13px;
  display: inherit;
  text-align: center;
  margin-bottom: 14px;
  font-size: 12px;
  opacity: 0;

  &.visible {
    opacity: 1;
  }
}
</style>
