<template>
  <div class="p-3">
    <div v-if="data && Object.keys(data).length">
      <span>
        <p v-if="data.adminName" class="mb-0">
          <strong>Admin: {{ data.adminName }}</strong>
        </p>
        <p v-if="data.adminId" class="mb-0">
          Admin ID: {{ data.adminId }}<strong>&nbsp;</strong>
        </p>
      </span>
      <span v-if="data.address">
        <p class="mb-0 mt-3"><strong>Address</strong></p>
        <p v-if="data.address.street" class="mb-0">
          Street: {{ data.address.street }}
        </p>
        <p v-if="data.address.city" class="mb-0">
          City: {{ data.address.city }}
        </p>
        <p v-if="data.address.state" class="mb-0">
          State/province/area: {{ data.address.state }}
        </p>
        <p v-if="data.address.country" class="mb-0">
          Country: {{ data.address.country }}
        </p>
      </span>
      <span v-if="data.currentLocation">
        <p class="mb-0 mt-3"><strong>Current Location</strong></p>
        <p class="mb-0">{{ data.currentLocation }}</p>
      </span>
    </div>
  </div>
</template>
<script>
export default {
  name: "AdminMarkerModal",
  props: {
    data: {},
  },
};
</script>
