<template>
  <div class="container text-center rounded bg-white session-container">
    <div class="row justify-content-center mt-md-5">
      <div class="col-12 col-md-7 my-5 card shadow-card p-5">
        <i class="fas fa-6x text-success fa-check my-4"></i>
        <h2 class="text-success">
          Your session has been completed successfully
        </h2>
        <h3 class="text-center" style="line-height: 1.6">
          If you just completed a check-in, it can take a while to analyze your
          data.
          <br />
          Once the analyzing is done, you can find the session results under
          your sessions list.
        </h3>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "OkayaReturn",
  computed: {
    src: function () {
      return this.$route.query.src;
    },
  },
};
</script>

<style lang="scss" scoped>
.session-container {
  min-height: 100vh;
  margin-left: -12px;
  max-width: calc(100% + 24px);
  width: calc(100% + 24px);
  background: hsla(186, 33%, 94%, 1);
  background: linear-gradient(
    90deg,
    hsla(186, 33%, 94%, 1) 0%,
    hsla(216, 41%, 79%, 1) 100%
  );
  background: -moz-linear-gradient(
    90deg,
    hsla(186, 33%, 94%, 1) 0%,
    hsla(216, 41%, 79%, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    hsla(186, 33%, 94%, 1) 0%,
    hsla(216, 41%, 79%, 1) 100%
  );
  overflow: hidden;
  .fullscreen-element {
    right: 15px;
    top: 15px;
    font-size: 2rem;
    color: #000;
    transition: transform 0.3s;
    cursor: pointer;
    &:hover {
      transform: scale(1.1);
    }
  }
  .expand {
    position: absolute !important;
  }
  .compress {
    position: fixed !important;
    z-index: 2147483647 !important;
  }
}
</style>
