<template>
  <modal
    name="seen-by-modal"
    transition="pop-out"
    class="seen-by-modal"
    :width="420"
    :focus-trap="true"
    :height="500"
  >
    <div class="seen-by-modal-container">
      <div class="modal-title border-bottom text-secondary position-relative">
        Seen By
        <button class="close mr-3 pr-2" @click="closeModal">&times;</button>
      </div>
      <div class="p-3 content-container border-bottom">
        <div
          class="flex row mx-0 mb-3 py-3 card row p-2"
          style="flex-direction: row"
          v-for="user in users"
          :key="user.id"
        >
          <img
            :src="user.profile_picture || profileImagePlaceholder"
            class="user-image"
          />
          <div class="d-flex flex-column">
            <span class="text-primary font-weight-bold">{{ user.text }}</span>
            <span class="text-secondary">{{ user.value }}</span>
          </div>
        </div>
      </div>
      <div class="text-center">
        <button class="btn btn-outline-blue" @click="closeModal">Close</button>
      </div>
    </div>
  </modal>
</template>

<script>
export default {
  name: "SeenByModal",
  props: {
    users: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      profileImagePlaceholder: require("@/assets/img/avatars/profiles/avatar.jpg"),
    };
  },
  methods: {
    closeModal: function () {
      this.$modal.hide("seen-by-modal");
    },
  },
};
</script>

<style scoped lang="scss">
.seen-by-modal-container {
  height: 100%;

  .content-container {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100% - 140px);
    margin-bottom: 24px;
  }
  .modal-title {
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    font-size: 20px;

    .close {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
    }
  }

  .user-image {
    object-fit: cover;
    border-radius: 100%;
    margin-right: 12px;
    height: 35px;
    width: 35px;
  }
}
</style>
