<template>
  <modal
    name="recordingViewModal"
    transition="pop-out"
    class="recording-view-modal"
    :width="800"
    :height="500"
  >
    <div class="position-relative modal-outer-container">
      <div class="modal-header border-bottom">
        <div>
          {{ roomName }} recording |
          {{ prettyDate(recording.recording_start) }}
        </div>
        <button class="btn border-0" @click="closeModal">
          <i class="fas fa-times fa-lg"></i>
        </button>
      </div>
      <div class="body-inner-container">
        <div class="card-body">
          <div class="loading-text" v-if="!videoIsReady">
            <span>Video being loading...</span>
          </div>
          <div>
            <video
              ref="videoPlayer"
              class="video-player vjs-big-play-centered"
              playsinline="true"
              controlslist="nodownload"
              controls
              @canplay="onPlayerCanplay($event)"
            >
              <source :src="recording.url" type="video/mp4" />
            </video>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>
<script>
import dayjs from "dayjs";
export default {
  name: "RecordingViewModal",
  props: {
    roomName: {
      type: String,
      required: true,
    },
    recording: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      videoIsReady: false,
    };
  },
  watch: {
    recording: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.videoIsReady = false;
      }
    },
  },
  methods: {
    closeModal() {
      this.$modal.hide("recordingViewModal");
    },
    prettyDate: function (date, onlyDate = false) {
      if (onlyDate) {
        return dayjs(date).format("MMM DD, YYYY");
      } else {
        return dayjs(date).format("MMM DD, YYYY  hh:mm A");
      }
    },
    onPlayerCanplay() {
      this.videoIsReady = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.recording-view-modal {
  .video-player {
    background-color: #000;
    width: 780px;
    height: 420px;
    @media (max-width: 767px) {
      width: 100% !important;
      height: 400px !important;
    }
  }
  .loading-text {
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 1.2rem;
    font-weight: 600;
    z-index: 1000;
    animation: loading 1.5s infinite;
    @keyframes loading {
      0% {
        opacity: 0.5;
      }
      50% {
        opacity: 1;
      }
      100% {
        opacity: 0.5;
      }
    }
  }
  .modal-header {
    color: #000;
    font-weight: 600;
    font-size: 1.2rem;
    > div {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    @media (max-width: 767px) {
      font-size: 1rem;
    }
  }
}
</style>
