<template>
  <div>
    <alert v-if="loading" class="shadow-card" />
    <div v-else-if="error">
      <div class="container">
        <div class="alert alert-danger mt-3" v-html="error" />
      </div>
    </div>
    <div v-else class="container pb-3">
      <div
        class="form-group noPrint d-flex justify-content-end align-items-end"
      >
        <button
          type="button"
          class="btn btn-lg btn-danger noPrint"
          @click="print"
        >
          <i class="fas fa-print mr-2"></i>Print/Save
        </button>
      </div>
      <div
        class="print-section client-note-print-section py-4 px-2 preview-only"
      >
        <table style="width: 100%" class="avoid-break-before">
          <tbody>
            <tr>
              <td>
                <div class="table-content">
                  <div
                    class="
                      header
                      d-flex
                      flex-column
                      justify-content-center
                      align-items-center
                    "
                  >
                    <img
                      v-show="companyData.logo"
                      class="company-logo"
                      :src="companyData.logo"
                      @load="logoReady = true"
                    />
                    <h2 class="title mt-3 mb-0 text-center text-capitalize">
                      {{ note.name }}
                    </h2>
                  </div>
                  <FormRenderer
                    v-if="formTemplate && formData"
                    class="col-12"
                    :key="note.id"
                    :form-configuration="formTemplate"
                    v-model="formData"
                  />
                  <div class="footer mt-5">
                    <div class="d-flex justify-content-between">
                      <div>
                        <p class="m-0">{{ companyData.name }}</p>
                        <p class="m-0" v-if="companyData.show_email_in_report">
                          {{ companyData.email }}
                        </p>
                        <p
                          class="m-0"
                          v-if="companyData.show_location_in_report"
                        >
                          {{ companyData.location }}
                        </p>
                      </div>
                      <div>
                        <p
                          class="m-0"
                          v-if="companyData.show_phone_number_in_report"
                        >
                          {{ companyData.phone_number }}
                        </p>
                        <p class="m-0" v-if="companyData.show_lawyer_in_report">
                          {{ companyData.lawyer }}
                        </p>
                      </div>
                    </div>
                    <div
                      class="m-0 w-100 mt-4 text-center h5 text-danger"
                      style="display: none"
                    >
                      {{ (note.data || {}).timeZone || "" }}
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="justify-content-center mt-4 mb-4 d-flex noPrint">
        <button
          type="button"
          class="btn btn-lg btn-danger noPrint"
          @click="print"
        >
          <i class="fas fa-print mr-2"></i>Print/Save
        </button>
      </div>
    </div>
    <loader class="noPrint loader" v-if="printWaiting">
      <b class="mt-4">Preparing note to print...</b>
    </loader>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { debounce } from "lodash";
export default {
  data() {
    return {
      loading: false,
      logoReady: false,
      formData: {},
      formTemplate: null,
      note: null,
      companyData: {},
      printWaiting: false,
      error: null,
    };
  },
  mounted() {
    window.addEventListener("resize", this.updateTextareasHeight);
  },
  destroyed() {
    window.removeEventListener("resize", this.updateTextareasHeight);
  },
  created() {
    this.loading = true;
    this.getFormByToken(this.token).then((res) => {
      if (res) {
        this.formTemplate = res.data.template.body;
        this.note = res.data.form;
        this.companyData = res.data.company;
        this.updateAutoFillData();
        setTimeout(() => {
          this.updateAutoFillData();
        }, 1000);
        setTimeout(() => {
          this.updateTextareasHeight();
        }, 300);
      } else {
        this.error =
          "The clinical note you are trying to access is not available. Please contact the company for more information.";
      }
      this.loading = false;
    });
  },
  computed: {
    token: function () {
      return this.$route.params.token;
    },
  },
  methods: {
    ...mapActions({
      getFormByToken: "templates/getFormByToken",
    }),
    print: function () {
      this.printWaiting = true;
      const interval = setInterval(() => {
        if (this.logoReady || !this.companyData.logo) {
          clearInterval(interval);
          this.printWaiting = false;
          window.print();
        }
      }, 500);
    },
    updateAutoFillData: function () {
      setTimeout(() => {
        this.formData = this.note?.data;
      }, 100);
    },
    updateTextareasHeight: debounce(function () {
      window.$("textarea").each(function () {
        window.$(this).height(60);
        let height = window.$(this).prop("scrollHeight");
        height = height > 60 ? height : 60;
        window.$(this).height(height);
      });
    }, 200),
  },
};
</script>
<style scoped lang="scss">
@page {
  size: auto;
  margin: none;
}
.loader {
  left: 0;
  right: 0;
  border: 0;
  background: rgba(255, 255, 255, 0.98) !important;
  top: 0;
  z-index: 9999;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
}
.client-note-print-section {
  border: 1px solid #c1c4d0;
  background-color: white;
  box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
  border-radius: 12px;
  margin-left: auto;
  margin-right: auto;
  overflow: auto;
}
.preview-only * {
  pointer-events: none;
}
.company-logo {
  position: absolute;
  left: 0;
  max-width: 30%;
  max-height: 90%;
}
.title {
  width: 40%;
  margin: 0 auto;
}
.header {
  position: relative;
  border-bottom: 1px solid lightgray;
  padding-bottom: 20px;
  min-height: 150px;
}

.footer {
  color: gray;
  border-top: 1px solid lightgray;
  padding-top: 20px;
}
</style>

<style lang="scss">
.preview-only {
  .tabs ul li {
    pointer-events: all !important;
  }
}
.note-header,
.header-space,
.note-footer,
.footer-space {
  height: 100px;
  display: none;
}
.note-header,
.note-footer {
  position: absolute;
  width: 100%;
  left: 0;
}

.note-header {
  top: 0;
}
.note-footer {
  bottom: 0;
}
.client-note-print-section {
  .col-md-1 {
    select {
      min-width: 60px;
      margin-left: -8px;
    }
  }
}
@media print {
  @page {
    margin: 4mm;
  }
  .main-container {
    margin-top: 0;
  }
  .note-header,
  .header-space {
    display: block;
  }
  .note-footer,
  .footer-space {
    display: none;
  }
  .client-note-print-section {
    padding-left: 0 !important;
    padding-right: 0 !important;
    border: 0 !important;
    background-color: transparent !important;
  }
  .vue-form-renderer .control-view,
  .vue-form-renderer .control-view-wrapper {
    break-inside: avoid;
    page-break-inside: avoid;
    z-index: 9;
  }
}
</style>
