<template>
  <modal
    name="task-history-modal"
    transition="pop-out"
    class="task-history-modal"
    :width="420"
    :focus-trap="true"
    :height="600"
  >
    <div class="task-history-modal-container">
      <div
        class="
          modal-title
          border-bottom
          text-black
          position-relative
          text-center
        "
      >
        Edit History
        <button class="close mr-3 pr-2 text-black" @click="closeModal">
          &times;
        </button>
      </div>
      <div
        class="p-3 content-container border-bottom body-bg mb-0"
        v-if="history.length"
      >
        <div
          class="col-12 mx-0 mb-3"
          v-for="(historyItem, index) in history"
          :key="index"
        >
          <div
            class="
              history-date
              text-dark-red
              font-weight-bold
              mb-2
              small
              border-bottom
            "
          >
            {{ getItemDate(historyItem.created_at) }}
          </div>
          <div
            class="white-bg py-3 p-2 history-box light-shadow"
            v-html="
              historyItem.message.replaceAll(
                '<user_id></user_id>',
                `<b>${historyItem.user_name}</b>`
              )
            "
          ></div>
        </div>
      </div>
      <div
        class="
          p-3
          content-container
          border-bottom
          body-bg
          mb-0
          d-flex
          align-items-center
          justify-content-center
        "
        v-if="!history.length"
      >
        <alert class="my-4 shadow-card" :hideLoader="true"
          >No edit history to show</alert
        >
      </div>
      <div class="text-center mt-2 pt-1">
        <button class="btn btn-theme" @click="closeModal">Close</button>
      </div>
    </div>
  </modal>
</template>

<script>
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

export default {
  name: "TaskHistoryModal",
  props: {
    history: {
      type: Array,
      required: true,
    },
    title: String,
  },
  methods: {
    closeModal: function () {
      this.$modal.hide("task-history-modal");
    },
    getItemDate: function (value) {
      return dayjs(value)
        .utc()
        .add(dayjs(value).utcOffset(), "minute")
        .format("YYYY-MM-DD (hh:mm A)");
    },
  },
};
</script>

<style lang="scss">
.task-history-modal-container {
  height: 100%;

  .content-container {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100% - 108px);
    margin-bottom: 24px;
  }
  .modal-title {
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    font-size: 20px;

    .close {
      font-size: 34px;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
    }
  }
  .history-date {
    font-family: cursive;
  }
  .body-bg {
    background-color: var(--body-bg-color);
  }
  .history-box {
    word-wrap: break-word;
    background-clip: border-box;
    border: 1px solid #edf2f9;
    border-radius: 0.5rem;
  }
  .old-message,
  .new-message {
    border: 2px solid #a6bec6;
    border-radius: 6px;
    padding: 8px;
    margin-top: 4px;
  }
  .old-message {
    opacity: 0.7;
    margin-bottom: 4px;
  }
  del:not(.colorless) {
    color: darkred;
  }
}
</style>
