<template>
  <div class="p-3">
    <div v-if="data && Object.keys(data).length">
      <span>
        <p v-if="data.patientName" class="mb-0">
          <strong>Client: {{ data.patientName }}</strong>
        </p>
        <p v-if="data.patientId" class="mb-0">
          Client ID: {{ data.patientId }}<strong>&nbsp;</strong>
        </p>
      </span>
      <span v-if="data.address">
        <p class="mb-0 mt-3"><strong>Address</strong></p>
        <p v-if="data.address.street" class="mb-0">
          Street: {{ data.address.street }}
        </p>
        <p v-if="data.address.city" class="mb-0">
          City: {{ data.address.city }}
        </p>
        <p v-if="data.address.state" class="mb-0">
          State/province/area: {{ data.address.state }}
        </p>
        <a
          v-if="data.address.phone"
          class="mb-0"
          :href="`tel:${data.address.phone}`"
          >Phone number: {{ data.address.phone }}</a
        >
        <p v-if="data.address.country" class="mb-0">
          Country: {{ data.address.country }}
        </p>
      </span>
      <span v-if="data.condition">
        <p class="mb-0 mt-3"><strong>Condition</strong></p>
        <p class="mb-0">{{ data.condition }}</p>
      </span>
      <span v-if="data.visits">
        <p class="mb-0 mt-3"><strong>Visits</strong></p>
        <p v-if="data.visits.days" class="mb-0">{{ data.visits.days }}</p>
        <p v-if="data.visits.times" class="mb-0">{{ data.visits.times }}</p>
      </span>
      <span v-if="data.assignedProvider">
        <p class="mb-0 mt-3"><strong>Provider assigned</strong></p>
        <p class="mb-0">
          {{ data.assignedProvider.providerName }}, ID:
          {{ data.assignedProvider.providerId }}
        </p>
      </span>
    </div>
  </div>
</template>
<script>
export default {
  name: "PatientMarkerModal",
  props: {
    data: {},
  },
};
</script>
