<template>
  <div>
    <alert v-if="loading" class="shadow-card" />
    <div v-else-if="error">
      <div class="container">
        <div class="alert alert-danger mt-3" v-html="error" />
      </div>
    </div>
    <div v-else class="container pb-3">
      <div
        class="form-group noPrint d-flex justify-content-end align-items-end"
      >
        <button
          type="button"
          class="btn btn-lg btn-danger noPrint"
          @click="print"
        >
          <i class="fas fa-print mr-2"></i>Print/Save
        </button>
      </div>
      <div
        class="print-section invoice-item-outer-container py-4 px-3"
        ref="invoicePrintableContainer"
      >
        <div class="invoice-item-container position-relative">
          <div class="row">
            <div class="col-5 col-sm-2 mr-2 mr-sm-4 reportCompanyLogo">
              <img
                :src="companyData.logo"
                style="max-width: 100%; max-height: 100%; object-fit: contain"
              />
            </div>
            <div class="col-6">
              <h2>
                <b>{{ companyData.name }}</b>
              </h2>
              <h4 v-if="companyData.location">{{ companyData.location }}</h4>
              <h4 v-if="companyData.phone_number">
                {{ companyData.phone_number }}
              </h4>
              <h4 v-if="companyData.email">{{ companyData.email }}</h4>
              <h4 v-if="companyData.url">{{ companyData.url }}</h4>
            </div>
          </div>
          <h1 class="mt-4">INVOICE</h1>
          <div class="row">
            <div class="col-4">
              <h4><b>BILL TO:</b></h4>
              <h4>{{ patient.last }}, {{ patient.first }}</h4>
              <h4>
                {{ patient.userAddressLine1 }}
              </h4>
              <h4>
                {{ patient.userAddressLine2 }}
              </h4>
            </div>
            <div class="col-4">
              <h4><b>SHIP TO:</b></h4>
              <h4>{{ patient.last }}, {{ patient.first }}</h4>
              <h4>
                {{ patient.userAddressLine1 }}
              </h4>
              <h4>
                {{ patient.userAddressLine2 }}
              </h4>
            </div>
            <div class="col-4">
              <h4>
                <b>INVOICE # {{ invoice.id }}</b>
              </h4>
              <h4>
                <b>DATE : {{ invoice.created }}</b>
              </h4>
              <h4>
                <b>DUE DATE : {{ invoice.due }}</b>
              </h4>
            </div>
          </div>

          <hr class="mb-5" />

          <table class="table table-striped mt-3 mb-0">
            <thead>
              <tr>
                <th style="width: 30%">Activity</th>
                <th style="width: 30%" class="text-center">Memo</th>
                <th style="width: 10%" class="text-right">Qty</th>
                <th style="width: 10%" class="text-right">Rate</th>
                <th style="width: 10%" class="text-right">Expenses</th>
                <th style="width: 10%" class="text-right">Interest(%)</th>
                <th style="width: 10%" class="text-right">Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="record in billingData"
                :key="record.id"
                :class="`${record.isDeleted ? 'deleted-record' : ''}`"
              >
                <td>
                  <b> {{ record.service != null ? record.service.name : "" }}</b
                  ><br />
                  {{ record.service != null ? record.service.description : "" }}
                </td>
                <td class="text-center">
                  {{
                    (record.appointment.memo != null &&
                      record.appointment.memo) ||
                    ""
                  }}
                </td>
                <td class="text-right">
                  {{ +parseFloat(record.qty || 0).toFixed(2) }}
                </td>
                <td class="text-right">
                  ${{ +parseFloat(record.rate || 0).toFixed(2) }}
                </td>
                <td class="text-right">
                  ${{
                    `${+parseFloat(
                      (record.appointment.expenses != null &&
                        record.service &&
                        record.service.parent_service_id == null &&
                        record.appointment.expenses) ||
                        0
                    ).toFixed(2)}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                  }}
                </td>
                <td class="text-right">
                  {{
                    `${+parseFloat(record.interest_percentage || 0).toFixed(
                      2
                    )}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                  }}%
                </td>
                <td class="text-right">
                  {{
                    `${+parseFloat(record.sub_total).toFixed(2)}`.replace(
                      /(\d)(?=(\d{3})+(?!\d))/g,
                      "$1,"
                    )
                  }}
                </td>
              </tr>
            </tbody>
          </table>

          <hr class="dashed" />

          <div class="row">
            <h3 class="col-8 text-right">Sub Total:</h3>
            <h3 class="col-4 text-right">
              <s class="mr-1" v-if="hasDeleted"
                >${{
                  `${+parseFloat(subTotalPaymentWithDeleted || 0).toFixed(
                    2
                  )}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                }}</s
              >
              ${{
                `${+parseFloat(subTotalPayment || 0).toFixed(2)}`.replace(
                  /(\d)(?=(\d{3})+(?!\d))/g,
                  "$1,"
                )
              }}
            </h3>
          </div>
          <div class="row">
            <h3 class="col-8 text-right">Interest Amount:</h3>
            <h3 class="col-4 text-right">
              <s class="mr-1" v-if="hasDeleted"
                >${{
                  `${+parseFloat(interestAmountWithDeleted || 0).toFixed(
                    2
                  )}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                }}</s
              >
              ${{
                `${+parseFloat(interestAmount || 0).toFixed(2)}`.replace(
                  /(\d)(?=(\d{3})+(?!\d))/g,
                  "$1,"
                )
              }}
            </h3>
          </div>
          <div class="row">
            <h3 class="col-8 text-right">Total (inc. tax):</h3>
            <h3 class="col-4 text-right">
              <s class="mr-1" v-if="hasDeleted"
                >${{
                  `${+parseFloat(totalPaymentwithDeleted || 0).toFixed(
                    2
                  )}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                }}</s
              >
              ${{
                `${+parseFloat(totalPayment || 0).toFixed(2)}`.replace(
                  /(\d)(?=(\d{3})+(?!\d))/g,
                  "$1,"
                )
              }}
            </h3>
          </div>
          <div class="row">
            <h3 class="col-8 text-right">Balance Due (inc. tax):</h3>
            <h2 class="col-4 text-right">
              <s class="mr-1" v-if="hasDeleted"
                >${{
                  `${+parseFloat(totalPaymentwithDeleted || 0).toFixed(
                    2
                  )}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                }}</s
              >
              ${{
                `${+parseFloat(totalPayment || 0).toFixed(2)}`.replace(
                  /(\d)(?=(\d{3})+(?!\d))/g,
                  "$1,"
                )
              }}
            </h2>
          </div>
          <div style="position: absolute; bottom: 16px" v-if="paid">
            <img src="@/assets/img/paid.png" alt="Paid" style="width: 200px" />
          </div>
        </div>
        <div v-if="isInterestCompounded" class="small mt-1 text-muted">
          *Compounded interest is applied to the invoice.
        </div>
      </div>
      <div class="justify-content-center mt-4 mb-4 d-flex noPrint">
        <button
          type="button"
          class="btn btn-lg btn-danger noPrint"
          @click="print"
        >
          <i class="fas fa-print mr-2"></i>Print/Save
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import helpers from "@/utils/helpers";
import Printd from "printd";
export default {
  data() {
    return {
      loading: false,
      logoReady: false,
      invoice: {
        id: "",
        created: "",
        due: "",
      },
      companyData: {
        name: "",
        location: "",
        phone_number: "",
        email: "",
        url: "",
        logo: "",
      },
      printWaiting: false,
      error: null,
      userID: 0,
      totalPayment: 0,
      totalPaymentwithDeleted: 0,
      subTotalPayment: 0,
      subTotalPaymentWithDeleted: 0,
      interestAmount: 0,
      interestAmountWithDeleted: 0,
      isInterestCompounded: false,
      hasDeleted: false,
      paid: false,
      billingData: [],
      patient: {
        first: "",
        last: "",
        userAddressLine1: "",
        userAddressLine2: "",
        id: "",
      },
      cssText: `.reportCompanyLogo img {
                  height: 150px;
                }

                h4 {
                  font-weight: 100;
                }
                hr {
                  border: 0;
                  border-top: 1px solid #000;
                }

                .dashed {
                  border-top: 1px dashed rgba(0, 0, 0, 0.2);
                }
                .deleted-record td,
                .deleted-record b {
                  text-decoration: line-through;
                  }
                `,
    };
  },
  async mounted() {
    this.loading = true;
    const res = await this.getInvoiceByToken(this.token);
    if (res) {
      const { invoice, company, invoice_data } = res.data;
      if (invoice_data.length === 0) {
        this.error =
          "The invoice you are trying to access does not have any data. Please contact the company for more information.";
        this.loading = false;
        return;
      }
      this.userID = invoice.patient_id;
      this.billingData = [...invoice_data];
      this.totalPayment = 0;
      this.interestAmount = 0;
      this.subTotalPayment = 0;
      this.totalPaymentwithDeleted = 0;
      this.subTotalPaymentWithDeleted = 0;
      this.interestAmountWithDeleted = 0;
      this.hasDeleted = false;
      this.isInterestCompounded = false;
      this.paid = this.billingData.filter((item) => item.paid_at).length > 0;
      this.billingData.forEach((record) => {
        if (
          record.service &&
          (record.service.unit == "FT" || record.service.unit == "FLAT")
        ) {
          record.qty = 1;
        }
        this.subTotalPaymentWithDeleted += record.sub_total;
        this.interestAmountWithDeleted += record.interest_amount;
        this.totalPaymentwithDeleted +=
          record.total_without_interest + record.interest_amount;
        this.isInterestCompounded =
          this.isInterestCompounded || record.is_compound;
        if (!record.isDeleted) {
          this.subTotalPayment += record.sub_total;
          this.interestAmount += record.interest_amount;
          this.totalPayment +=
            record.total_without_interest + record.interest_amount;
        } else {
          this.hasDeleted = true;
        }
      });

      let dueDate = new Date(invoice.created_at);
      dueDate = dueDate.setMonth(dueDate.getMonth() + 1);
      //Format the invoice information
      this.invoice = {
        id: invoice.id,
        created: this.prettyDate(invoice.created_at, "MM/DD/YYYY"),
        due: this.prettyDate(dueDate, "MM/DD/YYYY"),
      };
      //Set the patient information
      this.patient = {
        id: invoice.patient.id,
        first: invoice.patient.first,
        last: invoice.patient.last,
        userAddressLine1: invoice.patient.userAddressLine1,
        userAddressLine2: invoice.patient.userAddressLine2,
      };
      //Set the company information
      this.companyData = {
        name: company.name,
        location: company.location,
        phone_number: company.phone_number,
        email: company.email,
        url: company.url,
        logo: company.logo,
      };
    } else {
      this.error =
        "The invoice you are trying to access is not available. Please contact the company for more information.";
    }
    this.loading = false;
  },
  computed: {
    token: function () {
      return this.$route.params.token;
    },
  },
  methods: {
    ...mapActions({
      getInvoiceByToken: "invoices/getInvoiceByToken",
    }),
    prettyDate: function (date, format) {
      return helpers.prettyDate(date, format);
    },
    print() {
      let printer = new Printd();
      printer.print(this.$refs.invoicePrintableContainer, [
        "https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css",
        this.cssText,
      ]);
    },
  },
};
</script>
<style scoped lang="scss">
.print-section {
  border: 1px solid #c1c4d0;
  background-color: white;
  box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
  border-radius: 12px;
  margin-left: auto;
  margin-right: auto;
  overflow: auto;
}
.reportCompanyLogo {
  img {
    height: 150px;
  }
}
h4 {
  font-weight: 100;
}
hr {
  border: 0;
  border-top: 1px solid #000;
}

.dashed {
  border-top: 1px dashed rgba(0, 0, 0, 0.2);
}
.deleted-record {
  td,
  b {
    text-decoration: line-through;
  }
}

@media print {
  @page {
    margin: auto;
  }
}
</style>
