<template>
  <div :class="`mt-3 text-left emoji-reaction-container  ${wrapClass}`">
    <div class="emoji-reaction">
      <img
        src="@/assets/img/icons/smile-plus.svg"
        width="24"
        height="24"
        role="button"
        class="mt-1"
        :id="`emoji-button${task.id}${!this.listDisplay ? 't' : ''}${
          this.isReply ? '_reply' : ''
        }`"
        @click="toggleEmojiPicker($event, task.id)"
      />
      <div
        class="emoji-picker"
        :id="`emoji-picker${task.id}${!this.listDisplay ? 't' : ''}${
          this.isReply ? '_reply' : ''
        }`"
      >
        <Picker
          v-show="emojiPickerToggled && task.id == emojiPickerToggled"
          :data="emojiIndex"
          :showPreview="false"
          set="google"
          :perLine="8"
          @select="storeEmoji"
        />
      </div>
      <span class="emojis">
        <span
          v-for="(count, emoji) in task.grouped_reactions"
          :key="emoji"
          :class="[myReaction(task.reactions, emoji) ? 'my-reaction' : '']"
        >
          <emoji
            :data="emojiIndex"
            :emoji="emoji"
            :size="23"
            :tooltip="true"
            @click="storeEmoji"
          />
          <span class="count">{{ count }}</span>
        </span>
      </span>
    </div>
    <div class="who-reacted" v-if="task.reactions && task.reactions.length">
      <button
        class="btn btn-link btn-sm p-0 mt-2"
        @click.prevent="showReactedUsersDialog"
      >
        Who Reacted?
      </button>
    </div>
    <modal
      :name="`reacted-users${task.id}${!this.listDisplay ? 't' : ''}${
        this.isReply ? '_reply' : ''
      }`"
      transition="pop-out"
      :width="420"
      :focus-trap="true"
      class="reacted-users-modal"
      :height="500"
    >
      <div class="reacted-users-container">
        <div class="modal-title border-bottom text-secondary position-relative">
          Users Reactions
        </div>
        <div class="p-3 content-container border-bottom">
          <div
            class="d-flex row flex-nowrap mx-0 mb-3 py-3 p-2"
            style="flex-direction: row"
            v-for="(reaction, index) in task.reactions"
            :key="index"
          >
            <div class="user-image">
              <img
                :src="reaction.user_profile_picture || profileImagePlaceholder"
              />
              <span class="emoji-on-image">
                <emoji :data="emojiIndex" :emoji="reaction.emoji" :size="22" />
              </span>
            </div>
            <div
              class="
                d-flex
                flex-column flex-grow-1
                position-relative
                info-container
              "
            >
              <span class="text-primary font-weight-bold">{{
                reaction.user_name
              }}</span>
              <span class="text-secondary" style="word-break: break-all">{{
                reaction.user_email
              }}</span>
              <small class="text-right text-small text-muted created_at">{{
                prettyDate(reaction.created_at)
              }}</small>
            </div>
          </div>
        </div>
        <div class="text-center">
          <button class="btn btn-outline-blue" @click.prevent="closeModal">
            Close
          </button>
        </div>
      </div>
    </modal>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import data from "emoji-mart-vue-fast/data/google.json";
import "emoji-mart-vue-fast/css/emoji-mart.css";
import { Picker, EmojiIndex, Emoji } from "emoji-mart-vue-fast";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);

let emojiIndex = new EmojiIndex(data, {
  recentLength: 5,
});
export default {
  props: {
    task: Object,
    isReply: Boolean,
    wrapClass: {
      type: String,
      default: "",
    },
    listDisplay: {
      type: Boolean,
      default: false,
    },
    user: Object,
    emojiPickerToggled: {
      type: Number,
      default: null,
    },
  },
  emits: ["selectEmoji"],
  components: {
    Picker,
    Emoji,
  },
  data() {
    return {
      emojiIndex: emojiIndex,
      showReactedUsers: false,
      profileImagePlaceholder: require("@/assets/img/avatars/profiles/avatar.jpg"),
    };
  },
  beforeDestroy() {
    document.removeEventListener("click", this.clickAway);
  },
  mounted() {
    document.addEventListener("click", this.clickAway);
  },
  computed: {
    myReaction: function () {
      return (reactions, emoji) => {
        return reactions.filter(
          (reaction) =>
            reaction.user_id == this.user.id && reaction.emoji == emoji
        )?.[0];
      };
    },
  },
  methods: {
    ...mapActions({
      addReaction: "task/addReaction",
      deleteReaction: "task/deleteReaction",
    }),
    toggleEmojiPicker: function (_, task_id) {
      const popup = document.getElementById(
        `emoji-picker${task_id}${!this.listDisplay ? "t" : ""}${
          this.isReply ? "_reply" : ""
        }`
      );
      const button = document.getElementById(
        `emoji-button${task_id}${!this.listDisplay ? "t" : ""}${
          this.isReply ? "_reply" : ""
        }`
      );
      const rect = button.getBoundingClientRect();
      let top = rect.bottom;
      if (window.innerHeight - rect.bottom > 420) {
        top = "100%";
      } else {
        top = "-417px";
      }
      popup.style.top = top;
      popup.style.left = "20px";
      this.$emit("togglePicker");
    },
    clickAway: function (e) {
      let elements = document.getElementsByClassName(
        "emoji-reaction-container"
      );
      let flag = false;
      for (const element of elements) {
        if (element.contains(e.target)) {
          flag = true;
          break;
        }
      }
      if (!flag) {
        this.$emit("clickAway");
      }
    },
    storeEmoji: function (emoji) {
      if (!this.isReactionExist(this.task, emoji.colons)) {
        this.updateReactions(this.task, {
          type: "ADD",
          task_id: this.task.id,
          emoji: emoji.colons,
          user_id: this.user.id,
          user_name: `${this.user.last}, ${this.user.first}`,
          user_email: this.user.email,
          user_profile_picture: this.user.profile_picture,
          created_at: new Date(),
        });
        this.addReaction({
          task_id: this.isReply ? this.task.task_id : this.task.id,
          emoji: emoji.colons,
          isReply: this.isReply,
          reply_id: this.task.id,
        });
      } else {
        this.updateReactions(this.task, {
          type: "DELETE",
          emoji: emoji.colons,
          user_id: this.user.id,
        });
        this.deleteReaction({
          task_id: this.isReply ? this.task.task_id : this.task.id,
          emoji: emoji.colons,
          isReply: this.isReply,
          reply_id: this.task.id,
        });
      }
      this.$emit("selectEmoji", emoji, this.task);
    },
    updateReactions: function (task, obj) {
      if (obj.type == "ADD") {
        task.reactions.push(obj);
        if (obj.emoji in task.grouped_reactions) {
          task.grouped_reactions[obj.emoji]++;
        } else {
          task.grouped_reactions[obj.emoji] = 1;
        }
      }
      if (obj.type == "DELETE") {
        const reactionIndex = task.reactions.findIndex(
          (item) => item.user_id == obj.user_id && item.emoji == obj.emoji
        );
        if (reactionIndex >= 0) {
          task.reactions.splice(reactionIndex, 1);
          if (task.grouped_reactions[obj.emoji] - 1 > 0) {
            task.grouped_reactions[obj.emoji]--;
          } else {
            delete task.grouped_reactions[obj.emoji];
          }
        }
      }
    },
    isReactionExist: function (task, emoji) {
      const userId = this.user.id;
      const reaction = task.reactions.filter(
        (reaction) => reaction.emoji == emoji && reaction.user_id == userId
      )?.[0];
      return reaction ? true : false;
    },
    showReactedUsersDialog: function () {
      this.$modal.show(
        `reacted-users${this.task.id}${!this.listDisplay ? "t" : ""}${
          this.isReply ? "_reply" : ""
        }`
      );
    },
    closeModal: function () {
      this.$modal.hide(
        `reacted-users${this.task.id}${!this.listDisplay ? "t" : ""}${
          this.isReply ? "_reply" : ""
        }`
      );
    },
    prettyDate: function (date) {
      return dayjs(date).fromNow();
    },
  },
};
</script>
<style lang="scss" scoped>
.emoji-reaction-container {
  .emoji-reaction {
    display: flex;
    position: relative;
    width: fit-content;
    justify-content: center;
    align-items: flex-start;
    gap: 5px;
    .emoji-picker {
      position: absolute;
      z-index: 99;

      @media (max-width: 767px) {
        left: -16px !important;
      }
    }
    img {
      transition: transform 0.2s ease-in-out;
    }
    img:hover {
      transform: scale(1.1);
    }
    .my-reaction {
      background-color: #5187ec1f !important;
      border-color: #5186ec !important;
    }
    .emojis {
      display: flex;
      flex-wrap: wrap;
      gap: 5px;
      margin-right: 1rem;
      cursor: pointer;
      > span {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #eee;
        border: 1px solid transparent;

        border-radius: 15px;
        padding: 2px 5px;
        transition: all 0.1s ease-in-out;
        > span {
          padding: 0;
        }
        &:hover {
          border: 1px solid #bfb9b9;
          background-color: #f1f1f1;
        }
        .count {
          font-size: 13px;
          font-weight: bold;
          padding: 0px 3px 0px 2px;
        }
      }
    }
  }
  .reacted-users-container {
    height: 100%;

    .content-container {
      overflow-y: auto;
      overflow-x: hidden;
      height: calc(100% - 140px);
      margin-bottom: 24px;
      .info-container {
        position: relative;
        .created_at {
          position: absolute;
          right: 0;
          bottom: -20px;
        }
      }
    }
    .modal-title {
      padding-top: 10px;
      padding-bottom: 10px;
      text-align: center;
      font-size: 20px;
    }

    .user-image {
      position: relative;
      border-radius: 100%;
      img {
        object-fit: cover;
        border-radius: 100%;
        margin-right: 12px;
        height: 45px;
        width: 45px;
      }
      .emoji-on-image {
        position: absolute;
        bottom: -9px;
        right: 5px;
      }
    }
  }
}
</style>
