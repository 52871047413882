var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:`mt-3 text-left emoji-reaction-container  ${_vm.wrapClass}`},[_c('div',{staticClass:"emoji-reaction"},[_c('img',{staticClass:"mt-1",attrs:{"src":require("@/assets/img/icons/smile-plus.svg"),"width":"24","height":"24","role":"button","id":`emoji-button${_vm.task.id}${!this.listDisplay ? 't' : ''}${
        this.isReply ? '_reply' : ''
      }`},on:{"click":function($event){return _vm.toggleEmojiPicker($event, _vm.task.id)}}}),_c('div',{staticClass:"emoji-picker",attrs:{"id":`emoji-picker${_vm.task.id}${!this.listDisplay ? 't' : ''}${
        this.isReply ? '_reply' : ''
      }`}},[_c('Picker',{directives:[{name:"show",rawName:"v-show",value:(_vm.emojiPickerToggled && _vm.task.id == _vm.emojiPickerToggled),expression:"emojiPickerToggled && task.id == emojiPickerToggled"}],attrs:{"data":_vm.emojiIndex,"showPreview":false,"set":"google","perLine":8},on:{"select":_vm.storeEmoji}})],1),_c('span',{staticClass:"emojis"},_vm._l((_vm.task.grouped_reactions),function(count,emoji){return _c('span',{key:emoji,class:[_vm.myReaction(_vm.task.reactions, emoji) ? 'my-reaction' : '']},[_c('emoji',{attrs:{"data":_vm.emojiIndex,"emoji":emoji,"size":23,"tooltip":true},on:{"click":_vm.storeEmoji}}),_c('span',{staticClass:"count"},[_vm._v(_vm._s(count))])],1)}),0)]),(_vm.task.reactions && _vm.task.reactions.length)?_c('div',{staticClass:"who-reacted"},[_c('button',{staticClass:"btn btn-link btn-sm p-0 mt-2",on:{"click":function($event){$event.preventDefault();return _vm.showReactedUsersDialog.apply(null, arguments)}}},[_vm._v(" Who Reacted? ")])]):_vm._e(),_c('modal',{staticClass:"reacted-users-modal",attrs:{"name":`reacted-users${_vm.task.id}${!this.listDisplay ? 't' : ''}${
      this.isReply ? '_reply' : ''
    }`,"transition":"pop-out","width":420,"focus-trap":true,"height":500}},[_c('div',{staticClass:"reacted-users-container"},[_c('div',{staticClass:"modal-title border-bottom text-secondary position-relative"},[_vm._v(" Users Reactions ")]),_c('div',{staticClass:"p-3 content-container border-bottom"},_vm._l((_vm.task.reactions),function(reaction,index){return _c('div',{key:index,staticClass:"d-flex row flex-nowrap mx-0 mb-3 py-3 p-2",staticStyle:{"flex-direction":"row"}},[_c('div',{staticClass:"user-image"},[_c('img',{attrs:{"src":reaction.user_profile_picture || _vm.profileImagePlaceholder}}),_c('span',{staticClass:"emoji-on-image"},[_c('emoji',{attrs:{"data":_vm.emojiIndex,"emoji":reaction.emoji,"size":22}})],1)]),_c('div',{staticClass:"d-flex flex-column flex-grow-1 position-relative info-container"},[_c('span',{staticClass:"text-primary font-weight-bold"},[_vm._v(_vm._s(reaction.user_name))]),_c('span',{staticClass:"text-secondary",staticStyle:{"word-break":"break-all"}},[_vm._v(_vm._s(reaction.user_email))]),_c('small',{staticClass:"text-right text-small text-muted created_at"},[_vm._v(_vm._s(_vm.prettyDate(reaction.created_at)))])])])}),0),_c('div',{staticClass:"text-center"},[_c('button',{staticClass:"btn btn-outline-blue",on:{"click":function($event){$event.preventDefault();return _vm.closeModal.apply(null, arguments)}}},[_vm._v(" Close ")])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }