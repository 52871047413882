<template>
  <div>
    <alert v-if="loading" class="shadow-card" />
    <div v-else class="container">
      <div class="row my-2">
        <div class="col-12 col-md-8 text-truncate">
          <h1>{{ roomName || "Meeting" }} Room</h1>
        </div>
        <div class="col text-md-right">
          <button class="btn btn-outline-blue" @click="copyMeetingLink">
            <span v-if="copied">link copied!</span>
            <span v-else> Invite Participant </span>
          </button>
        </div>
      </div>
      <div v-if="baseUrl">
        <whereby-embed
          :room="getRoomUrl()"
          class="meeting-container shadow-card rounded overflow-hidden"
        ></whereby-embed>
      </div>
    </div>
  </div>
</template>
<script>
import "@whereby.com/browser-sdk";
import { mapActions } from "vuex";
export default {
  data() {
    return {
      loading: false,
      baseUrl: null,
      copied: false,
    };
  },
  created() {
    this.loading = true;
    this.getWherebyOrganizationUrl().then((res) => {
      if (res) {
        this.loading = false;
        this.baseUrl = res.data.url;
      }
    });
  },
  computed: {
    roomSlug: function () {
      return this.$route.params.slug;
    },
    roomKey: function () {
      return this.$route.query.roomKey;
    },
    roomName: function () {
      return this.$route.query.roomName;
    },
  },
  methods: {
    ...mapActions({
      getWherebyOrganizationUrl: "rooms/getWherebyOrganizationUrl",
    }),
    getRoomUrl: function () {
      if (this.roomKey) {
        return `${this.baseUrl}/${this.roomSlug}?roomKey=${this.roomKey}`;
      } else {
        return `${this.baseUrl}/${this.roomSlug}`;
      }
    },
    copyMeetingLink: function () {
      const vm = this;
      let url = window.location.href.split("?")[0];
      if (this.roomName) {
        url += `?roomName=${this.roomName}`;
      }
      vm.copied = true;
      navigator.clipboard.writeText(url).then(
        function () {
          setTimeout(() => {
            vm.copied = null;
          }, 700);
        },
        function (err) {
          console.error("Async: Could not copy text: ", err);
        }
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.meeting-container {
  height: calc(100vh - 70px);
}
</style>
