import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import EssentialsPlugin from "@ckeditor/ckeditor5-essentials/src/essentials";
import BoldPlugin from "@ckeditor/ckeditor5-basic-styles/src/bold";
import ItalicPlugin from "@ckeditor/ckeditor5-basic-styles/src/italic";
import LinkPlugin from "@ckeditor/ckeditor5-link/src/link";
import ParagraphPlugin from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import HorizontalLine from "@ckeditor/ckeditor5-horizontal-line/src/horizontalline";
import Font from "@ckeditor/ckeditor5-font/src/font";
import BlockQuote from "@ckeditor/ckeditor5-block-quote/src/blockquote";
import Highlight from "@ckeditor/ckeditor5-highlight/src/highlight";
import Underline from "@ckeditor/ckeditor5-basic-styles/src/underline";
import Strikethrough from "@ckeditor/ckeditor5-basic-styles/src/strikethrough";
import Heading from "@ckeditor/ckeditor5-heading/src/heading";
import ListStyle from "@ckeditor/ckeditor5-list/src/liststyle";
import Mention from "@ckeditor/ckeditor5-mention/src/mention";
import Alignment from "@ckeditor/ckeditor5-alignment/src/alignment";
import TodoList from "@ckeditor/ckeditor5-list/src/todolist";
import {
  Emoji,
  EmojiActivity,
  EmojiFlags,
  EmojiFood,
  EmojiNature,
  EmojiObjects,
  EmojiPeople,
  EmojiPlaces,
  EmojiSymbols,
} from "../../assets/emojiPlugin/src";
/*
 * This plugin customizes the way mentions are handled in the editor model and data.
 * Instead of a classic <span class="mention"></span>,
 */
export const MentionLinks = function (editor) {
  // The upcast converter will convert a view
  //
  //		<a href="..." class="mention" data-mention="...">...</a>
  //
  // element to the model "mention" text attribute.
  editor.conversion.for("upcast").elementToAttribute({
    view: {
      name: "a",
      key: "data-mention",
      classes: "mention",
      attributes: {
        mentionId: true,
      },
    },
    model: {
      key: "mention",
      value: (viewItem) =>
        editor.plugins.get("Mention").toMentionAttribute(viewItem),
    },
    converterPriority: "high",
  });

  // Downcast the model "mention" text attribute to a view
  //
  //		<a mentionId="..." class="mention" data-mention="...">...</a>
  //
  // element.
  editor.conversion.for("downcast").attributeToElement({
    model: "mention",
    view: (modelAttributeValue, { writer }) => {
      // Do not convert empty attributes (lack of value means no mention).
      if (!modelAttributeValue) {
        return;
      }

      return writer.createAttributeElement(
        "a",
        {
          class: "mention",
          "data-mention": modelAttributeValue.id,
          href: `mailto:${modelAttributeValue.name}`,
          "data-mention-id": modelAttributeValue.user_id,
        },
        {
          // Make mention attribute to be wrapped by other attribute elements.
          priority: 20,
          // Prevent merging mentions together.
          id: modelAttributeValue.uid,
        }
      );
    },
    converterPriority: "high",
  });
};

/*
 * Customizes the way the list of user suggestions is displayed.
 * Each user has an @id, a name and an avatar.
 */
const customItemRenderer = function (item) {
  const itemElement = document.createElement("div");
  const itemInfoElement = document.createElement("div");
  const avatar = document.createElement("img");
  const userNameElement = document.createElement("span");
  const fullNameElement = document.createElement("span");

  itemElement.classList.add("mention__item", "d-flex", "align-items-center");

  itemInfoElement.classList.add("d-flex", "flex-column");

  avatar.src = require("@/assets/img/avatars/profiles/avatar.jpg");

  if (item.profile_picture) {
    avatar.src = item.profile_picture;
  }

  userNameElement.classList.add("mention__item__user-name", "text-secondary");

  userNameElement.textContent = item.name;

  fullNameElement.classList.add(
    "mention__item__full-name",
    "text-primary",
    "font-weight-bold"
  );
  fullNameElement.textContent = `${item.id.replace("@", "")}`;

  itemInfoElement.appendChild(fullNameElement);
  itemInfoElement.appendChild(userNameElement);

  itemElement.appendChild(avatar);
  itemElement.appendChild(itemInfoElement);

  return itemElement;
};

export const getFeedItems = function (queryText, usersArray) {
  // As an example of an asynchronous action, return a promise
  // that resolves after a 100ms timeout.
  // This can be a server request or any sort of delayed action.
  const users = usersArray.map((user) => {
    return {
      id: `@${user.text}`,
      user_id: user.id,
      name: user.value,
      profile_picture: user.profile_picture,
      email: user.email,
    };
  });
  return new Promise((resolve) => {
    setTimeout(() => {
      const itemsToDisplay = users
        // Filter out the full list of all items to only those matching the query text.
        .filter(isItemMatching);
      // Return 10 items max - needed for generic queries when the list may contain hundreds of elements.
      // .slice(0, 10);

      resolve(itemsToDisplay);
    }, 100);
  });

  // Filtering function - it uses the `name` and `username` properties of an item to find a match.
  function isItemMatching(item) {
    // Make the search case-insensitive.
    const searchString = queryText.toLowerCase();

    // Include an item in the search results if the name or username includes the current user input.
    return (
      item.name.toLowerCase().includes(searchString) ||
      item.id.toLowerCase().includes(searchString)
    );
  }
};

export const richTextConfig = {
  classicEditor: ClassicEditor,
  placeholder: "Type your message...",
  basicToolbar: {
    toolbar: {
      items: [
        "heading",
        "fontSize",
        "|",
        "fontColor",
        "fontBackgroundColor",
        "highlight",
        "|",
        "bold",
        "italic",
        "underline",
        "Strikethrough",
        "link",
        "blockQuote",
        "horizontalLine",
        "|",
        "numberedList",
        "bulletedList",
        "todoList",
        "alignment",
        "emoji",
        "|",
        "undo",
        "redo",
      ],
      viewportTopOffset: 76,
      shouldNotGroupWhenFull: true,
    },
    fontSize: {
      options: [9, 11, 13, "default", 17, 19, 21, 23, 26],
    },
    fontColor: {
      colors: [
        {
          color: "hsl(0, 0%, 0%)",
          label: "Black",
        },
        {
          color: "hsl(0, 0%, 30%)",
          label: "Dim grey",
        },
        {
          color: "hsl(0, 0%, 60%)",
          label: "Grey",
        },
        {
          color: "hsl(0, 0%, 90%)",
          label: "Light grey",
        },
        {
          color: "hsl(0, 0%, 100%)",
          label: "White",
          hasBorder: true,
        },
        {
          color: "hsl(0, 75%, 60%)",
          label: "Red",
        },
        {
          color: "hsl(30, 75%, 60%)",
          label: "Orange",
        },
        {
          color: "hsl(60, 75%, 60%)",
          label: "Yellow",
        },
        {
          color: "hsl(90, 75%, 60%)",
          label: "Light green",
        },
        {
          color: "hsl(120, 75%, 60%)",
          label: "Green",
        },
        {
          color: "hsl(150, 75%, 60%)",
          label: "Aquamarine",
        },
        {
          color: "hsl(180, 75%, 60%)",
          label: "Turquoise",
        },
        {
          color: "hsl(210, 75%, 60%)",
          label: "Light blue",
        },
        {
          color: "hsl(240, 75%, 60%)",
          label: "Blue",
        },
        {
          color: "hsl(270, 75%, 60%)",
          label: "Purple",
        },
      ],
    },
    fontBackgroundColor: {
      colors: [
        {
          color: "hsl(0, 75%, 60%)",
          label: "Red",
        },
        {
          color: "hsl(30, 75%, 60%)",
          label: "Orange",
        },
        {
          color: "hsl(60, 75%, 60%)",
          label: "Yellow",
        },
        {
          color: "hsl(90, 75%, 60%)",
          label: "Light green",
        },
        {
          color: "hsl(120, 75%, 60%)",
          label: "Green",
        },
      ],
    },
    highlight: {
      options: [
        {
          model: "greenMarker",
          class: "marker-green",
          title: "Green marker",
          color: "var(--ck-highlight-marker-green)",
          type: "marker",
        },
        {
          model: "yellowMarker",
          class: "marker-yellow",
          title: "Yellow marker",
          color: "var(--ck-highlight-marker-yellow)",
          type: "marker",
        },
        {
          model: "redMarker",
          class: "marker-red",
          title: "light marker",
          color: "var(--ck-highlight-pen-red)",
          type: "marker",
        },
      ],
    },
  },
  plugins: [
    EssentialsPlugin,
    BoldPlugin,
    ItalicPlugin,
    LinkPlugin,
    ParagraphPlugin,
    HorizontalLine,
    BlockQuote,
    Highlight,
    Underline,
    Strikethrough,
    ListStyle,
    Mention,
    Heading,
    Alignment,
    TodoList,
    Font,
    MentionLinks,
    Emoji,
    EmojiPeople,
    EmojiNature,
    EmojiPlaces,
    EmojiFood,
    EmojiActivity,
    EmojiObjects,
    EmojiSymbols,
    EmojiFlags,
  ],
  feeds: {
    marker: "@",
    itemRenderer: customItemRenderer,
    minimumCharacters: 0,
  },
  getFeedItems: getFeedItems,
};
