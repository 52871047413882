<template>
  <div class="p-3">
    <div v-if="data && Object.keys(data).length">
      <span>
        <p v-if="data.providerName" class="mb-0">
          <strong>Provider: {{ data.providerName }}</strong>
        </p>
        <p v-if="data.providerId" class="mb-0">
          Provider ID: {{ data.providerId }}<strong>&nbsp;</strong>
        </p>
      </span>
      <span v-if="data.address">
        <p class="mb-0 mt-3"><strong>Address</strong></p>
        <p v-if="data.address.street" class="mb-0">
          Street: {{ data.address.street }}
        </p>
        <p v-if="data.address.city" class="mb-0">
          City: {{ data.address.city }}
        </p>
        <p v-if="data.address.state" class="mb-0">
          State/province/area: {{ data.address.state }}
        </p>
        <a
          v-if="data.address.phone"
          class="mb-0"
          :href="`tel:${data.address.phone}`"
          >Phone number: {{ data.address.phone }}</a
        >
        <p v-if="data.address.country" class="mb-0">
          Country: {{ data.address.country }}
        </p>
      </span>
      <span v-if="data.currentLocation">
        <p class="mb-0 mt-3"><strong>Current Location</strong></p>
        <p class="mb-0">{{ data.currentLocation }}</p>
      </span>
      <span v-if="data.patientsAssigned">
        <p class="mb-0 mt-3"><strong>Clients assigned</strong></p>
        <p class="mb-0">{{ data.patientsAssigned }} clients</p>
      </span>
    </div>
  </div>
</template>
<script>
export default {
  name: "ProviderMarkerModal",
  props: {
    data: {},
  },
};
</script>
