const TASK_TYPES = [
  { value: 1, label: "Created" },
  { value: 2, label: "Request For Notes" },
  { value: 3, label: "Call Log" },
  { value: 4, label: "Administrative Note" },
  { value: 5, label: "Follow Up Request" },
  { value: 6, label: "Request For Report" },
  { value: 7, label: "Message" },
  { value: 8, label: "Appointment Request" },
  { value: 9, label: "New Client" },
  { value: 10, label: "Request For Information" },
  { value: 11, label: "Urgent Request" },
  { value: 12, label: "In Progress" },
  { value: 13, label: "Pending" },
  { value: 14, label: "Received" },
  { value: 15, label: "Completed" },
  { value: 16, label: "Follow Up Required" },
  { value: 17, label: "Edits Required" },
  { value: 18, label: "Monthly Calendar Verified" },
  { value: 19, label: "New Client Request" },
  { value: 20, label: "New Referral" },
  { value: 21, label: "Provider Availability Update" },
  { value: 22, label: "End Of Day Update" },
  { value: 23, label: "Billing Update" },
  { value: 24, label: "Client Budget Update" },
  { value: 25, label: "File Review" },
  { value: 26, label: "Request For Meeting" },
  { value: 27, label: "Payroll Inquiry" },
  { value: 28, label: "Administrative Update" },
  { value: 29, label: "Correspondence With External Providers" },
  { value: 30, label: "Scheduling Inquiry" },
  { value: 31, label: "Treatment Plan Request" },
  { value: 32, label: "Budget" },
  { value: 33, label: "Urgent Treatment Plan Request" },
  { value: 34, label: "Referral To Professional" },
  { value: 35, label: "Provider Needed" },
  { value: 36, label: "Discharge Request" },
  { value: 37, label: "Signature Request" },
  { value: 38, label: "Document Release" },
  { value: 39, label: "Clinical Note Update" },
  { value: 40, label: "Missing Clinical Notes" },
];

export default {
  TASK_TYPES,
};
